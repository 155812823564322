.back-button-container {
  text-align: left;
  margin-top: 1rem;
}

.back-button {
  text-align: center;
}

@media only screen and (max-width: 1000px) {
  .back-button-container {
    margin-bottom: 2rem;
    margin-top: 0rem;
  }
}

@media print {
  .back-button-container {
    display: none;
  }
}
