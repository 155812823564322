.icon {
    height: 1rem;
    display: inline block;
    margin-bottom: -3px;
    margin-left: 4px;
}

.new-test-screen-links, #test-screen-list {
    font-size: 1rem;
}

.new-test-screen-links {
    font-weight: bold;
    transition: 0.4s;
    color: #BE3E4A;
}

.new-test-screen-links:hover {
    color: #FF5C6C;
    transition: 0.4s;
}

#new-test-screen-title {
    text-align: center;
    position: relative;
    margin-bottom: 1.10rem;
    background-color: white;
    color: black;
}

#test-screen-list {
    margin: 2rem 0rem;
    padding-left: 1rem;
}

.download-list-item {
    margin-bottom: .75rem;
}

#new-test-screen-form {
    margin-top: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

#new-test-screen-button {
    display: inline-block;
    cursor: pointer;
    width: 8rem;
    text-align: center;
    transition: 0.4s;    
    border-style: solid;
    border-color: #2F7ABC;
    background-color: #2F7ABC;
    padding: 0.75em;
    border-radius: 0;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
}

#new-test-screen-button:hover, #new-test-screen-button:focus {
    border-width: 2px;
    border-style: solid;
    border-color: #1D4C72;
    background-color: #1D4C72;
    color: white;
    text-decoration: underline;
}

#new-test-desc {
    text-align: justify;
    margin: 2rem auto;
}

@media (max-width : 1000px) {
    #new-test-screen-title { margin: 0 }
    #new-test-screen-form { margin-bottom: 2rem; margin-left: 0; justify-content: center; }
    #new-test-desc { width: 100% }
    #new-test-right-button { display: block; margin: auto; }
}
