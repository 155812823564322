#ManageTestsScreen-main-container {
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

#content-container {
  margin: auto;
  margin-top: 1.5rem;
  height: 100%;
}

#content {
  justify-content: center;
  margin: auto;
}

#content table {
  text-align: center;
}

#manage-my-tests-title {
  text-align: center;
  margin: auto;
  margin-bottom: -1rem;
}

#manage-all-tests-title {
  text-align: center;
  margin-bottom: -1rem;
  margin-top: 3rem;
}

.manage-test-table-cells {
  width: 15rem;
}

#test-saved-date-box {
  width: 20rem;
}

.table-row-gray-color {
  background-color: rgb(228, 228, 228);
}

.manage-test-a-tag {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
}

.manage-test-a-tag:hover {
  color: #fe414d;
  transition: 0.4s;
}

@media (max-width : 1000px) {
  #content table tr th { font-size: 0.9rem; }
  #content-container { margin-top: 0rem; }
}