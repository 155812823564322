.back-button-container {
  text-align: left;
}

.back-button {
  text-align: center;
}

@media print {
  .back-button-container {
    display: none;
  }
}
