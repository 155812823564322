.account-overlay {
    background-color: lightgray;
    overflow: auto;
}

.account-input {
    position: relative;
}

.dropdown-button, .dropdown-button:hover, .dropdown-button:focus, .dropdown-button:focus-visible {
    background-color: unset;
    color: black;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
}

.listbox {
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 400px;
    width: 100%;
}

.option {
    background-color: transparent;
    color: black;
    padding: 2px 5px;
    outline: none;
    cursor: pointer;
}

.option.selected {
    background-color: #FF5B6C;
    color: white;
}

.option.focused {
    background-color: black;
    color: white;
}