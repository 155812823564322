.btn-inline {
    padding: 0.25em;
    margin-left: 0.5em;
}

.page-header {
    text-align: center;
}

.page-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

label[for="addPage"] {
    display: block;
}

#addPage, .addPageBtn {
    display: inline-block;
}

#addPage {
    width: 78%;
}

.addPageBtn {
    padding: 0.7em;
    margin-left: 1em;
}

.table-section {
    margin-bottom: 2em;
}

.table-section h2 {
    text-align: center;
}

.pages.table-container {
    box-shadow: none;
    border: 1px solid;
}

table.dark {
    table-layout: fixed;
    border-spacing: 0;
    text-align: left;
    width: 100%;
}

table.dark thead {
    background-color: black;
    height: 4em;
    color: #FFF;
    border-radius: 25px;
}

table.dark tr {
    height: 4em;
    line-height: 1.5;
}

table.dark th {
    width: 10%;
    border-collapse:collapse;
    border-top: 1px solid;
    padding: 1rem 2rem;
}

table.dark td {
    border-collapse:collapse;
    border-top: 1px solid;
}

table.dark th:first-child {
    width: 10%;
}

table.dark td:last-child,
table.dark th:last-child {
    width: 20%;
}

table.dark td:last-child {
    text-align: center;
}

table.dark th:nth-child(2) {
    width: 80%;
}

table.dark td:nth-child(2) {
    text-align: center;
}

table.dark [scope="col"] {
    text-align: center;
    border-top: none;
}