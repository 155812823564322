#creds {
    padding-top: 2rem;
    padding-bottom: 3rem;
}

body {
    margin: 0;
}

.ntslink {
    font-weight: bold;
    color: #BE3E4A;
    transition: 0.4s;
}

.ntslink:hover {
    color: #FF5C6C;
    transition: 0.4s;
    
}

#intro-screen-title {
    text-align: center;
}

#intro-container {
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#title {
    position: relative;
    color: black;
    margin: auto;
    background-color: transparent;
    margin-top: .75rem;
    margin-bottom: 1rem;
}

#intro-main-text {
    text-align: justify;
}

#inline-new-test-link {
    margin-left: .25rem;
}