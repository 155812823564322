.font-heading-3 {
  font-family: "OpenSans", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  padding-top: 20px;
  font-size: 1.25rem;
  border-bottom: double 3px #000;
}
.deque-table-sortable-group table {
  border-collapse: collapse;
  border-spacing: 0;
  overflow: visible;
  width: 100%;
  min-width: 1000px;
}
.deque-table-sortable-group table thead {
  border-bottom: 1px solid #e3e3e3;
}
.deque-table-sortable-group table thead th {
  position: relative;
}
.deque-table-sortable-group table thead th:last-child {
  border-right: none;
  border-radius: 0 25px 0 0;
}

.deque-table-sortable-group table thead th:first-child {
  border-radius: 25px 0 0 0;
}

.deque-table-sortable-group table thead th .sortableColumnLabel {
  color: white;
  display: inline-block;
  height: 100%;
  cursor: pointer;
  padding: 0 0 0 0;
  text-align: center;
  position: relative;
}

.unsortableColumnLabel {
  color: white;
  height: 100%;
  cursor: default;
  padding: 0 0 0 0;
  text-align: center;
}

.deque-table-sortable-group table thead th .sortableColumnLabel:hover {
  outline: 1px dashed white;
}
.deque-table-sortable-group table thead th button {
  color: white;
  background: transparent;
  border: 0;
  padding-left: 0;
  font-size: inherit;
  font-weight: inherit;
  font-family: "Futura";
}
.deque-table-sortable-group table thead th button:hover {
  margin: 0;
}
.deque-table-sortable-group table thead th button:focus {
  margin: 0;
  z-index: 1;
}
.deque-table-sortable-group table thead th button:active {
  color: #000000;
  outline: 1px solid transparent;
  margin: 0;
}
.deque-table-sortable-group table thead th[colspan]:not([colspan="1"]) {
  text-align: center;
}
.deque-table-sortable-group table thead th .sortableColumnLabel:after {
  /* This is the up and down arrow SVG. */
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 41 57' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle /%3E%3Cdesc /%3E%3Cdefs /%3E%3Cg fill='none' fill-rule='evenodd' id='Icons' stroke='none' stroke-width='1'%3E%3Cg fill='%23fff' stroke='%23000' id='XXX-Artboard-1' transform='translate(-1239.000000, -138.000000)'%3E%3Cg id='Double-Arrows' transform='translate(1239.000000, 138.150000)'%3E%3Cpath d='M10.5623119,31.5480425 C9.30468998,32.6283056 8.28518598,32.1695935 8.28518598,30.5136396 L8.28518598,-6.51787882 C8.28518598,-8.16942688 9.29767763,-8.63856827 10.5623119,-7.55228175 L31.0451159,10.041891 C32.3027378,11.1221541 32.3097502,12.8675832 31.0451159,13.9538698 L10.5623119,31.5480425 Z' id='Triangle-1' transform='translate(20.138075, 11.997111) rotate(-90.000000) translate(-20.138075, -11.997111) ' /%3E%3Cpath d='M10.5623119,63.8182709 C9.30468998,64.898534 8.28518598,64.4398218 8.28518598,62.7838679 L8.28518598,25.7523495 C8.28518598,24.1008014 9.29767763,23.6316601 10.5623119,24.7179466 L31.0451159,42.3121194 C32.3027378,43.3923825 32.3097502,45.1378116 31.0451159,46.2240981 L10.5623119,63.8182709 Z' id='Triangle-1' transform='translate(20.138075, 44.267339) scale(1, -1) rotate(-90.000000) translate(-20.138075, -44.267339) ' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  height: 1.0625rem;
  width: 0.76425439rem;
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translate(100%, -50%);
  display: inline-block;
}
.deque-table-sortable-group
  table
  thead
  th[aria-sort]
  .sortableColumnLabel:after {
  /* This is the down arrow SVG. */
  content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 41 57' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle /%3E%3Cdesc /%3E%3Cdefs /%3E%3Cg fill='none' fill-rule='evenodd' id='Icons' stroke='none' stroke-width='1'%3E%3Cg fill='%23fff' stroke='%23000' id='XXX-Artboard-1' transform='translate(-1239.000000, -138.000000)'%3E%3Cg id='Double-Arrows' transform='translate(1239.000000, 138.150000)'%3E%3Cpath d='M10.5623119,63.8182709 C9.30468998,64.898534 8.28518598,64.4398218 8.28518598,62.7838679 L8.28518598,25.7523495 C8.28518598,24.1008014 9.29767763,23.6316601 10.5623119,24.7179466 L31.0451159,42.3121194 C32.3027378,43.3923825 32.3097502,45.1378116 31.0451159,46.2240981 L10.5623119,63.8182709 Z' id='Triangle-1' transform='translate(20.138075, 44.267339) scale(1, -1) rotate(-90.000000) translate(-20.138075, -44.267339) ' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.deque-table-sortable-group
  table
  thead
  th[aria-sort="descending"]
  .sortableColumnLabel:after {
  transform: scaleY(-1) translate(100%, 50%);
}
.deque-table-sortable-group table thead:before {
  content: "";
  display: block;
  width: 7px;
}
.deque-table-sortable-group table thead td,
.deque-table-sortable-group table thead th {
  text-align: center;
  vertical-align: middle;
}
.deque-table-sortable-group table thead {
  background-color: black;
  height: 4em;
}
.deque-table-sortable-group table tbody tr {
  cursor: default;
  line-height: 1.5;
}
.deque-table-sortable-group table tbody td th {
  text-align: center;
  padding: 7px;
  border-right: 1px solid #cccccc;
  height: 100%;
  cursor: pointer;
  border-bottom: 2px black solid;
}
.deque-table-sortable-group td,
.deque-table-sortable-group th {
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px black solid;
  padding: 1rem 2rem;
}
.deque-table-sortable-group table tbody td:last-child,
.deque-table-sortable-group table tbody th:last-child {
  border-right: none;
}

.deque-table-sortable-group tbody > tr:last-child > td {
  border-bottom: 0;
}

.deque-table-sortable-group tbody > tr:last-child > th {
  border-bottom: 0;
}

.deque-table-sortable-group table thead th:last-child {
  border-right: none;
  border-radius: 0 25px 0 0;
}

.deque-table-sortable-group table tbody td:last-child,
.deque-table-sortable-group table tbody th:last-child {
  border-right: none;
}

.deque-table-sortable-group td {
  text-align: center;
  vertical-align: middle;
}

#company-specific-table__sort-instructions {
  display: none;
}

#account-overview-table__sort-instructions {
  display: none;
}

.accNameLabel:hover {
  text-decoration: underline;
}

.accNameLabel {
  cursor: pointer;
}

#account-col {
  width: 30%;
}

#table-title {
  font-weight: bold;
  padding-top: 2rem;
  font-size: 1.25rem;
}

.table-link, .table-link:visited {
  color: black;
}

@media screen and (max-width : 1000px) {
  .accNameLabel { line-height: 30px; }
  .deque-table-sortable-group table thead th { font-size: 0.8rem; }
  .deque-table-sortable-group td { font-size: 0.9rem }
  .deque-table-sortable-group table thead th { padding: 1rem 2rem; }
}

@media print {
  .deque-table-sortable-group table thead th .sortableColumnLabel::after {
    display: none;
  }

  .table-link {
    text-decoration: none;
  }
}