#login-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #ffffff;
}

#login-picture {
  width: 100%;
  height: 100%;
  margin: auto;
  width: 50%;
  margin-top: 2%;
  object-fit: contain;
  margin-left: 5%;
}

#login-container {
  display: flex;
  margin: auto;
  text-align: center;
  width: 100%;
}

#login-content-container {
  margin: auto;
  font-size: 1rem;
  width: 50%;
  display: inline-block;
  margin-right: 6%;
}

#login-main-title-container {
  position: absolute;
  display: flex;
  background-color: black;
  height: 2.5rem;
  width: 100%;
  padding-bottom: 2rem;
  padding: 1.25rem 0rem;
  top: 0;
  left: 0;
  transition: 0.4s;
}

#login-main-title {
  color: white;
  font-weight: none;
  font-size: 1.7rem;
  width: 100%;
  margin: 0;
  padding-left: 1.25rem;
}

#login-sub-title {
  font-size: 1.8rem;
  text-align: center;
  margin: 0;
  margin-bottom: 20px;
}

#login-container form {
  justify-content: center;
  width: 18rem;
  margin: auto;
  text-align: left;
}

.login-a-tags {
  font-size: 0.9rem;
  font-weight: bold;
  color: #eb0000;
}

.txtbox-labels {
  font-weight: bold;
}

.textbox {
  width: 100%;
  padding: 0.7rem 0.5rem;
  margin-bottom: 1rem;
  background-color: white;
  transition: 0.4s;
  border: gray solid 1px;
  border-radius: 3px;
  box-sizing: border-box;
}

.textbox:hover {
  background-color: #ebebeb;
  transition: 0.4s;
}

#sign-up-text {
  text-align: center;
}

#sign-in-button {
  width: 100%;
  margin-top: 10px;
  transition: 0.4s;
  font-size: 1rem;
  border-width: 2px;
  border-style: solid;
  border-color: #2f7abc;
  background-color: #2f7abc;
  padding: 0.5em;
  border-radius: 10px;
  color: white;
}

#sign-in-button:hover,
#sign-in-button:focus {
  cursor: pointer;
  transition: 0.4s;
  border-width: 2px;
  border-style: solid;
  border-color: #1d4c72;
  background-color: #1d4c72;
  color: white;
  text-decoration: underline;
}

#main-title {
  position: absolute;
  font-size: 1.2rem;

  margin: 0;
  text-align: center;
}

#login-dash {
  border-top: 1px solid black;
  width: 102%;
}

#login-page-fieldset {
  border-radius: 0.5rem;
  border: 0;
  height: 18rem;
  margin-top: 1rem;
}

#login-title-form {
  height: inherit;
}

#required-text {
  text-align: center;
}

#required-text p {
  margin-top: 5px;
}

@media (max-width: 1000px) {
  #login-picture {
    display: none;
  }
  #login-content-container {
    width: 100%;
    margin: 6rem auto auto;
  }
}

@media (max-height: 550px) {
  #login-picture {
    display: none;
  }
}

@media (max-width: 300px) {
  #login-container form {
    width: 80%;
  }
}

@media (width > 1500px) {
  #login-container form {
    width: 22rem;
  }
}
