#capdrop {
    padding-top: 1rem;
    padding-bottom: 2rem;
    color: black;
}

.capselector {
    color: black;
    width: 23.75rem;
    background-color: white;
    transition: 0.4s;
    border-color: grey;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='black' d='M12 14.975q-.2 0-.388-.075t-.312-.2l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z'/></svg>");
}

.capselector:hover , .capselector:focus {
    background-color: #ebebeb;
    transition: .4s;
}

.ssform {
    margin: 0 auto;
    font-size: 1rem;
    text-align: left;
    line-height: 1.5rem;
    width: 80%;
    padding-bottom: 1rem;
    color: black;
}

.msg-count {
    margin-left: 1rem;
}

.ssform form {
    width: 100%;
    margin-left: 0px;
    padding-top: 1rem;
    color: black;
}

.ssform input[type=text] {
    display: block;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    background-color: #fff;
    transition: 0.4s;
    border: gray solid 1px;
    border-radius: 3px;
    box-sizing: border-box;
}

.ssform input:hover {
    background-color: #ebebeb;
    transition: .4s;
}

#testNotes {
    display: block;
    padding: 0.5rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    transition: 0.4s;
    border: gray solid 1px;
    border-radius: 3px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    resize: both;   
    height: 15rem;   
    width: 100%;
}

#testedURL {
    display: block;
    padding: 0.5rem;
    margin-top: 0.1rem;
    margin-bottom: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #fff;
    transition: 0.4s;
    border: gray solid 1px;
    border-radius: 3px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    resize: both;   
    height: 5rem;   
    width: 50%;
}

.ssform textarea:hover {
    background-color: #ebebeb;
    transition: .4s;
}

.ssinstruc {
    text-align: justify;
}

.radioans {
    padding-top: 1rem;
}

.radioans label {
    display: block;
    margin-left: 2rem;
    text-align: justify;
    margin-bottom: 1rem;
}

.radioans input[type=radio] {
    position: absolute;
    filter: grayscale(1.3);
}

.radioans fieldset {
    border: none;
    padding-left: 0;
    margin-left: 0;
}

.radioans legend {
    margin-bottom: 1rem;
    font-weight: bold;
}

.collapse {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.3rem;
    border-bottom: #fff solid 3px;
    transition: 0.4s;
}

.ssform details[open] summary {
    color: #BE3E4A;
    border-bottom: #BE3E4A solid 3px;
}

.collapse:hover {
    color: #BE3E4A;
    border-bottom: #fff solid 3px;
    transition: 0.4s;
}

.collapse_info {
    background-color: #f4f4f4;
    padding: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
}

.collapse_info ol {
    margin-top: -0.1rem;
    margin-bottom: -1rem;
}

.collapse_info li {
    padding-left: 0.5rem;
    margin-bottom: 1rem;
    text-align: left;
}

.ssnav {
    padding-top: 1rem;
}

#ssbtn, #ssbtn2 {
    width: 7rem;
    padding: .75em;
}

#ssbtn2 {
    float: right;
    margin-left: 0.5rem;
}

#ssbtn3 {
    float: inline-end;
    padding: .75em;
}

#ssbtn:hover, #ssbtn2:hover, #ssbtn:focus, #ssbtn2:focus  {
    border-width: 2px;
    border-style: solid;
    border-color: #1D4C72;
    background-color: #1D4C72;
    color: white;
    text-decoration: underline;
    transition: .4s;
}

#newLine {
    white-space: break-spaces;
}

#pager {
    border-top: black dotted 1px;
    color: black;
    padding-top: 1rem;
    margin-left: 0;
    margin-right: 0;
    font-size: 1rem;
    display: inline-block;
    text-align: center;
    width: 100%;
}

#visual-example-image {
    width: 100%;
}

#testNotes {
    box-sizing: border-box;
}

#questions:focus{
    outline: 2px solid black;
    outline-offset: 4px;
}

.test-incomplete-error-cont {
    margin: 10px 0px;
}

.breadcrumb ol {
    display: flex;
    flex-direction: row;
    padding: 0;
}

.breadcrumb li {
    list-style: none;
}

.breadcrumb li:not(:last-child)::after {
    content: "/";
    padding: 10px;
}

.breadcrumb a {
    color: #BE3E4A;
}

.breadcrumb a:hover {
    color: #FF5C6C;
    transition: 0.4s;
}

.links-markdown {
    font-weight: bold;
    font-size: 1rem;
    color: #BE3E4A;
}
.links-markdown:hover {
    transition: 0.4s;
    color: #FF5C6C;
}

.table-title, .popeTech-results .table-header th {
    font-size: 1.2em;
    font-weight: bold;
}

.detail-modal {
    max-width: 45%;
}

td.cell-details {
    text-align: center;
    /* border: none; */
}

.popeTech-results {
    max-width: 60%;
}

@media only screen and (max-width: 1200px) {
    .ssform { margin: 0; width: 100%; }
    .scoring-body { display: block; margin: auto; width: 80%; }
    .popeTech-results {
        max-width: 100%;
    }
}

@media only screen and (max-width : 1000px) {
    .ssform input[type=text] { width: 100%; }
    .ssform h2 { margin-top: 0; }
    #testNotes { width: 100%; }
    #visual-example-image { width: 100%; }
    .capselector { width: 100%; }
    .scoring-body { width: 100%; margin: 0; }
    
}