#add-question {
  border-radius: 0.3rem;
}
.container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0rem;
}
.add-btn {
  color: white;
  background-color: #2f7abc;
  padding: 0.8rem 1.3rem;
  float: right;
  border-radius: 0.3rem;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.add-btn:hover {
  background-color: #1d4c72;
}
.edit-btn {
  padding: 0.09rem 0.35rem 0.09rem 0.35rem;
  background-color: #2f7abc;
  color: white;
  border: none;
  border-radius: 0.2rem;

  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.edit-btn:hover, .edit-btn:focus {
  background-color: #1d4c72;
  border: none;
}
.mui-edit-icon {
  font-size: 0.9rem !important;
  padding-top: 0.2rem;
}
.mui-delete-outline-icon {
  font-size: 1rem !important;
  margin-top: 0.1rem;
}
.row-content:last-child #question-data {
  border-bottom-left-radius: 1rem;
}
.row-content:last-child #delete-data {
  border-bottom-right-radius: 1rem;
}

.inner-headers {
  transform: translate3d(0, 0, 0);
  white-space: nowrap;
  background-color: #000;
}
.header-content {
  color: white;
  padding: 1.2rem 0.7rem 1.2rem 0.7rem;
}
.row-content:nth-child(odd) {
  background-color: rgb(243, 243, 243);
}
.row-content:hover {
  background-color: rgb(215, 215, 215);
}
.row-data {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  box-sizing: border-box;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  position: relative;
  padding: 0rem 0.7rem;
  color: rgba(0, 0, 0, 0.87);
}
.header-content,
.row-data {
  font-size: 0.875rem;
  font-weight: 400;
}
.question-col {
  text-align: center;
  width: 14%;
}
.criteria-col {
  width: 68%;
}
.edit-col {
  width: 8%;
}
.delete-col {
  border-top-right-radius: 1rem;
}
.actions-col {
  display: none;
}
#question-data {
  text-align: center;
}
#edit-data {
  padding-left: 0.7rem;
}
#delete-data {
  padding-left: 1.2rem;
}
#actions-data {
  display: none;
  text-align: center;
}
.table-container .table-header:first-child .inner-headers:first-child th {
  border-top-width: 0;
}
.table-container
  .table-header:first-child
  .inner-headers:first-child
  th:first-child {
  border-top-left-radius: 1rem;
}
.table-container
  .table-header:first-child
  .inner-headers:first-child
  th:last-child {
  border-top-right-radius: 1rem;
}

@media (max-width : 1000px) {
  .container-header {
    margin-top: 0rem;
  }
  .actions-col {
    width: 8%;
  }
  .actions-col, #actions-data {
    display: table-cell; 
  } 
  .edit-col, .delete-col, #edit-data, #delete-data {
    display: none;
  }
  .delete-btn {
    margin-top: 10px;
  }
}