.scoring-nav {
    display: flex;
}

.scoring-nav .list-cont {
    border-radius: 15px;
    overflow-y: scroll;
    position: relative;
    width: 100%;
    max-height: calc(100vh - 5rem);
}

.scoring-nav ol {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
}

.scoring-nav li:not(:first-child) {
    border-top: 1px solid grey;
}

.scoring-nav button {
    padding: 20px 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    background-color: transparent;
    color: black;
    border: none;
    border-radius: 0;
    width: 100%;
    text-align: left;
}

.scoring-nav button:hover {
    background-color: transparent;
    color: black;
    border: none;
}

.nav-icon {
    display: flex;
    width: 20px;
    font-weight: 500;
}

.nav-icon svg {
    height: 20px;
    width: 20px;
}

.nav-number {
    width: 20px;
    font-size: 1.2rem;
}

.nav-text {
    width: 100%;
}

.scoring-nav li button {
    background-color: rgb(213, 213, 213, 0.5);
}

.scoring-nav li button:focus {
    outline-offset: -7px;
    color: black;
    text-decoration: none;
}

.scoring-nav li button:hover {
    text-decoration: none;
}

.scoring-nav li button:disabled {
    background-color: rgb(213, 213, 213, 0.5);
    text-decoration: none;
    cursor: unset;
}

.scoring-nav li.active button {
    background-color: #FF5C6C;
    color: white;
    font-weight: bold;
}

.scoring-nav li.completed button {
    background-color: black;
    color: white;
}

.scoring-nav li.active button:focus, .scoring-nav li.completed button:focus {
    outline-color: #489AFE;
}

.scoring-nav button.arrow {
    display: none;
}

.scoring-nav .test-incomplete-error-cont {
    margin-bottom: 0px;
}

.scoring-nav li.active #test-incomplete-error {
    color: white;
}