.scoring-nav-drawer {
    position: fixed;
    left: 0px;
    top: 0px;
}

.scoring-nav-drawer summary::-webkit-details-marker {
    display: none;
}

.scoring-nav-drawer summary::marker {
    display: none;
    content: "";
}

.scoring-nav-drawer summary:focus .summary-icon, .scoring-nav-drawe summary:focus-visible .summary-icon {
    outline-offset: 5px;
    outline: 2px solid #005FCC;
}

.scoring-nav-drawer .summary-icon {
    position: absolute;
    top: 150px;
    right: -25px;
    z-index: 1;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border-color: #2F7ABC;
    background-color: #2F7ABC;
    color: white;
    cursor: pointer;
}

.scoring-nav-drawer .summary-icon svg {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(40%, 35%);
}

.scoring-nav-drawer details {
    border-right: 1px solid rgb(212, 212, 212);
    height: 100vh;
    position: relative;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgb(212, 212, 212));
    background-position: right;
    background-repeat: no-repeat;
    background-size: 30px 100%;
    padding-top: 5rem; /* height + padding of header nav */
}

.scoring-nav-drawer details[open] {
    width: 400px;
    border: none;
}

.scoring-nav-drawer details:not([open]) {
    width: 50px;
}

.scoring-nav-drawer details[open]::after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgb(212, 212, 212));
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.scoring-nav-drawer details[open] .summary-icon svg {
    transform: translate(30%, 35%);
}

.scoring-nav-drawer .scoring-nav .list-cont {
    border-radius: 0;
}

.scoring-nav-drawer .scoring-nav .list-cont li button:disabled   {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgb(212, 212, 212));
    background-position: right;
    background-repeat: no-repeat;
    background-size: 30px 100%;
}

.scoring-nav-drawer details[open] .close-icon {
    display: none;
}

.scoring-nav-drawer details:not([open]) .open-icon {
    display: none;
}

.scoring-nav-details:not([open]) .scoring-nav{
    display: none;
}