.mobile-scoring-nav dialog {
    padding: 0;
    position: relative;
}

.mobile-scoring-nav dialog::backdrop {
    background-color: rgba(54, 54, 54, 0.9);
}

.dialog-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.dialog-header h2 {
    margin-left: 10px;
}

.dialog-header .close-nav {
    display: flex;
}

.dialog-header .close-nav svg {
    height: 20px;
    width: 20px;
}

.mobile-scoring-nav .btn-cont {
    margin-bottom: 30px;
}

.mobile-scoring-nav .scoring-nav {
    max-height: unset;
    overflow-y: unset;
}

.mobile-scoring-nav .scoring-nav .list-cont {
    max-height: unset;
    overflow-y: unset;
    overflow: hidden;
    margin-top: 0;
    border-radius: unset;
    border: unset;
}