#upload-test-landing-screen-title {
    text-align: center;
    position: relative;
    margin-bottom: 1.10rem;
    background-color: white;
    color: black;
}

#upload-test-landing-screen-form {
    margin-top: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}

#upload-test-landing-screen-upload-container {
    margin-top: 1.5rem;
    display: flex;
    width: 50%;
    left: 25%;
    justify-content: center;
    border-radius: 10px;
    text-align: center;
    position: relative;
    display: inline-block;
    border-style: solid;
    border-color: black;
}

#upload-test-landing-screen-upload-icon {
    margin-top: 1.5rem;
    width: 15%;
    justify-content: center;
}

#upload-test-landing-screen-button {
    display: inline-block;
    cursor: pointer;
    width: 8rem;
    text-align: center;
    transition: 0.4s;    
    border-style: solid;
    border-color: #2F7ABC;
    background-color: #2F7ABC;
    padding: 0.75em;
    border-radius: 0;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
}

#upload-test-landing-screen-button:hover, #upload-test-landing-screen-button:focus {
    border-width: 2px;
    border-style: solid;
    border-color: #1D4C72;
    background-color: #1D4C72;
    color: white;
    text-decoration: underline;
}

#upload-test-landing-desc {
    text-align: justify;
    margin: 2rem auto;
}

#upload-test-landing-screen-upload-text {
    margin: 2rem;
}

#upload-test-landing-screen-upload-text-bold {
    margin: 2rem;
    font-weight: bold;
}

#remove-test-landing-screen-button {
    display: inline-block;
    cursor: pointer;
    width: 8rem;
    text-align: center;
    transition: 0.4s;    
    border-style: solid;
    border-color: #FE414D;
    background-color: #FE414D;
    padding: 0.75em;
    border-radius: 0;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
    margin-bottom: 2rem;
}

#remove-test-landing-screen-button:hover, #remove-test-landing-screen-button:focus {
    border-width: 2px;
    border-style: solid;
    border-color: #AB2730;
    background-color: #AB2730;
    color: white;
    text-decoration: underline;
}

#upload-test-landing-screen-container-after-upload {
    margin-top: 1rem;
    /* display: flex; */
    width: 100%;
    justify-content: center;
    text-align: center;
    position: relative;
}

#worksheetNameSelect {
    color: white;
    width: 20rem;
    height: 3.5rem;
    background-color: black;
    transition: 0.4s;
    border-color: grey;
    text-align: center;
    width: 20rem;
    margin-bottom: 1.5rem;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='black' d='M12 14.975q-.2 0-.388-.075t-.312-.2l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z'/></svg>");
}

#bottom-container {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    justify-content: right;
    text-align: right;
    position: relative;
    flex-direction: column;
    width: 50%;
    left: 25%;
    align-items: flex-end;
}

@media (max-width : 1000px) {
    #upload-test-landing-screen-title { margin: 0 }
    #upload-test-landing-screen-form { margin-bottom: 2rem; margin-left: 0; justify-content: center; }
    #upload-test-landing-desc { width: 100% }
    #upload-test-landing-right-button { display: block; margin: auto; }
}
