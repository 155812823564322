#rcontainer {
  margin: auto;
}

body {
  padding-left: 8% !important;
  padding-right: 8% !important;
}

table {
  table-layout: fixed;
  width: 100%;
}

th,
td,
tr {
  width: 25%;
  overflow: hidden;
  padding: 0px 10px 0px 10px
}

#rtable,
#rth,
#rtd {
  border-collapse: collapse;
  padding: 0.5rem 1rem;
}

#scoreh2 {
  font-family: "FuturaM";
  margin: 10px 0px;
  font-size: 2rem;
}

#scoreh2v2 {
  font-family: "FuturaM";
}

#dateLbl {
  margin-top: 0;
}

#rtd {
  vertical-align: text-top;
}

#reportHead {
  color: white;
  background-color: black;
  height: 4em;
}

#rtable thead th:first-child {
  border-radius: 25px 0 0 0;
}

#rtable thead th:last-child {
  border-radius: 0 25px 0 0;
}

.capability {
  padding: 2em;
}

.reportCells {
  padding-top: 2px;
  padding-bottom: 2px;
  padding: 1em;
  border-bottom: solid 2px #000;
  white-space: pre-line;
}

.editButton {
  padding-top: 2px;
  padding-bottom: 2px;
  padding: 1em;
  border-bottom: solid 2px #000;
  text-align: center;
}

.link-to-response{
  font-weight: bold;
  text-decoration: underline;
  color: #BE3E4A;
}

#rtable table tbody td:last-child,
#rtable table tbody th:last-child {
  border-right: none;
  border-radius: 0 25px 0 0;
}

#rtable tbody > tr:last-child > td {
  border-bottom: none;
}

#rtable tbody > tr:last-child > th {
  border-bottom: none;
}

#capabilityCol {
  text-align: center;
}


#pdfPopup {
  position: fixed;
  top: 35%;
  height: 20%;
  width: 40%;
  left: 30%;
  background-color: white;
  text-align: center;
  align-items: center;
  border: solid 2px #000;
  border-radius: 10px;
  padding-top: 20px;
}

td.guidance, td.urlNotes {
  vertical-align: top;
}

td.guidance > :first-child {
  margin-top: 0;
}

.links-markdown {
  font-weight: bold;
  font-size: 1rem;
  color: #BE3E4A;
}
.links-markdown:hover {
  transition: 0.4s;
  color: #FF5C6C;
}
.open-new-tab {
  height: 1rem;
  margin-bottom: -3px;
  margin-left: 4px;
}

#actionButtons {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}

.bottom-margin {
  margin-bottom: 1rem;
}

@media print {
  #reportHead {
    color: white;
  }

  .edit, .editButton {
    display: none;
  }

  .capability {
    border-radius: 0 25px 0 0;
  }
}

@media only screen and (max-width: 1000px) {
  #rtable {
    min-width: 500px;
  }

  #score {
    margin-top: 0;
    font-size: 2rem;
  }
}