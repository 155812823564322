.biform {
    margin: auto;
    max-width: 35rem;
    text-align: left;
}

.biform h1 {
    text-align: center;
}

.biform label {
    font-weight: bold;
}

.biform input[type=text],
.biform input[type=url] {
    width: 100%;
    padding: 0.7rem 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.7rem;
    transition: 0.4s;
    background-color: #fff;
    border: gray solid 1px;
    border-radius: 3px;
    box-sizing: border-box;
}

.biform input:hover, .biform input:focus {
    background-color: #ebebeb;
    transition: .4s;
}

.biform select {
    width: 100%;
    padding: 0.7rem 0.5rem;
    margin-top: 0.2rem;
    margin-bottom: 0.7rem;
    transition: 0.4s;
    background-color: white;
    border: gray solid 1px;
    border-radius: 3px;
    box-sizing: border-box;
    color: black;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path fill='black' d='M12 14.975q-.2 0-.388-.075t-.312-.2l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062Z'/></svg>");
}

.biform form {
    margin-left: 0px;
    width: 100%;
}

.biinstruc {
    text-align: left;
    margin: 2rem 0rem;
}

.additional-info {
    font-size: 0.85em;
}

.additional-info.bottom-margin {
    margin: 0 0 1em 0;
}

.nav {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    column-gap: 1rem;
}

.nav-single-button {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    justify-content: flex-end;
}

.nav > .btn, .nav-single-button > .btn {
    width: 8rem;
    padding: .75rem;
}

.nav > .btn:hover, .nav > .btn:focus {
    border-color: #1D4C72;
    background-color: #1D4C72;
    color: white;
    text-decoration: underline;   
}

@media (max-width : 1000px) {
    .biform { width: 100%; }
}