.header-theme {
  background-color: lightgrey;
  font-weight: bold;
}

.export-button {
  cursor: pointer;
  width: 10px;
  height: 10px;
  position: relative;
}

.export-button img {
  width: 80%;
  height: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-container {
  margin: auto;
}

@media print {
  /* Hide "export results" on print */
  #company-specific-table th:last-child, 
  #company-specific-table td:last-child {
    display: none;
  }
}