.scan-modal {
    width: 25em;
    text-align: center;
    position: relative;
}

.scan-modal .x {
    height: auto;
}

.scan-title {
    font-size: 2em;
}

.status-msg {
    text-transform: capitalize;
}

/* Spinner - https://loading.io/css/ */
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion) {
    .lds-ring div {
        animation: none;
    }
    .lds-ring div:nth-child(1) {
        transform: rotate(45deg);
    }
    .lds-ring div:nth-child(2) {
        transform: rotate(90deg);
    }
    .lds-ring div:nth-child(3) {
        transform: rotate(135deg);
    }
}